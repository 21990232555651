body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #F5F5F5;
}

.bordered-text {
  border: 1px solid #CCCCCC;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 5px;
  background-color: #FFFFFF;
}

/* Responsive styles */
@media (max-width: 768px) {
  header div {
    flex-direction: column;
    gap: 10px;
  }

  header div a, header div button, header div select {
    font-size: 12px;
  }

  main form input, main form select, main form button {
    width: 100% !important;
    margin: 5px 0;
  }

  main div[style*="display: flex"] {
    flex-direction: column;
    gap: 20px;
  }

  main h1, main h2 {
    font-size: 14px !important;
  }

  main p, main li, main label, main input, main textarea, main select, main button {
    font-size: 12px !important;
  }
}

a:focus, button:focus, input:focus, select:focus, textarea:focus {
  outline: 2px solid #0000FF;
  outline-offset: 2px;
}
